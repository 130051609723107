import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../store/AuthContext';
import isEmail from 'validator/es/lib/isEmail';
import Layout from '../components/ui/Layout';
import InputText from '../components/input/InputText';
import Button from '../components/input/Button';
import TaskTable from '../components/views/TaskTable';

function MyTasks() {
  const authContext = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setEmail(authContext.requesterEmail);
  }, [authContext.requesterEmail]);

  async function submitHandler(event: React.FormEvent) {
    event.preventDefault();
    if (!isEmail(email)) setEmailError(true);
    if (isEmail(email)) {
      setEmailError(false);
      authContext.setRequesterEmail(email);
    }
  }
  return (
    <Layout>
      {authContext.isAdmin && (
        <div className={'mx-auto bg-white rounded shadow px-4 py-8 mt-8'}>
          <form
            className={'text-left'}
            onSubmit={submitHandler}
            noValidate={true}
          >
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              Search Task Requests
            </h3>
            <div
              className={'mt-4 text-left grid grid-cols-1 gap-4 sm:grid-cols-4'}
            >
              <div className={'col-span-3'}>
                <InputText
                  placeHolder={'youremail@example.com'}
                  type={'email'}
                  showError={emailError}
                  errorMessage={'Enter valid email'}
                  label={'Email'}
                  name={'email'}
                  value={email}
                  setValue={(email) => {
                    setEmailError(false);
                    setEmail(email);
                  }}
                />
              </div>
              <div className={'col-span-1 mt-6'}>
                <Button
                  disabled={disabled}
                  type={'submit'}
                  buttonText={'Find Tasks'}
                  disabledText={'Finding ...'}
                />
              </div>
            </div>
          </form>
        </div>
      )}
      <div className='mx-auto mt-8'>
        <TaskTable />
      </div>
    </Layout>
  );
}

export default MyTasks;
