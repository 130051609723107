import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AuthContextProvider from './store/AuthContext';
import './index.css';
import App from './App';
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';

let redirectUrl = 'http://localhost:3000/';

if (window.location.hostname.includes('devfactory.com'))
  redirectUrl = 'https://p2-tasks.devfactory.com/';

if (window.location.hostname.includes('prod.d1pzyx2hs1s74w.amplifyapp.com'))
  redirectUrl = 'https://prod.d1pzyx2hs1s74w.amplifyapp.com/';

if (window.location.hostname.includes('dev.d1pzyx2hs1s74w.amplifyapp.com'))
  redirectUrl = 'https://dev.d1pzyx2hs1s74w.amplifyapp.com/';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
awsmobile.oauth.redirectSignIn = redirectUrl;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
awsmobile.oauth.redirectSignOut = redirectUrl;

Amplify.configure(awsmobile);

ReactDOM.render(
  <AuthContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthContextProvider>,
  document.getElementById('root'),
);
