export const BASE_URL =
  'https://aezusw0w46.execute-api.us-east-1.amazonaws.com/prod';

export const statusMap = new Map();
statusMap.set('Passed External QC', 'Done');
statusMap.set('Ready for Work', 'In Progress');
statusMap.set('Internal QC', 'In QC');
statusMap.set('Delivered', 'In Progress');
statusMap.set('External QC', 'In QC');
statusMap.set('Failed By Worker', 'Failed');
statusMap.set('Failed Internal QC', 'Failed');
statusMap.set('Failed External QC', 'Failed');

export const reverseStatusMap = new Map();
reverseStatusMap.set('Done', ['Passed External QC']);
reverseStatusMap.set('In Progress', ['Ready for Work', 'Delivered']);
reverseStatusMap.set('Failed', [
  'Failed By Worker',
  'Failed Internal QC',
  'Failed External QC',
]);
reverseStatusMap.set('In QC', ['External QC', 'Internal QC']);
