import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../store/AuthContext';
import { BASE_URL } from '../constants/constants';
import isEmail from 'validator/es/lib/isEmail';
import Layout from '../components/ui/Layout';
import InputText from '../components/input/InputText';
import InputTextarea from '../components/input/InputTextarea';
import Button from '../components/input/Button';
import Alert from '../components/ui/Alert';
import Checkbox from '../components/input/Checkbox';

function CreateTask() {
  const authContext = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState<'SUCCESS' | 'ERROR'>('SUCCESS');
  const [ticketKey, setTicketKey] = useState('');
  const [testTask, setTestTask] = useState(false);
  const [preAssignedWorker, setPreAssignedWorker] = useState('');

  useEffect(() => {
    setEmail(authContext.userEmail);
  }, [authContext.userEmail]);

  async function submitHandler(event: React.FormEvent) {
    event.preventDefault();
    if (!isEmail(email)) setEmailError(true);
    if (!title) setTitleError(true);
    if (!description) setDescriptionError(true);
    if (isEmail(email) && title && description) {
      setDescriptionError(false);
      setTitleError(false);
      setEmailError(false);
      try {
        setShowAlert(false);
        setDisabled(true);

        // creating P2 doc
        const createDoc = {
          parentId: '1ZKa92B4D0mqp9MHFEK_9Izd7L9TDAtwW',
          name: 'Some file name',
          templateId: '1z0-j6-7LSpgTg0qmn4BDLGhp0-NkaTQCFwjsNtI2gh8',
        };

        const createDocResponse = await fetch(`${BASE_URL}/file`, {
          method: 'POST',
          body: JSON.stringify(createDoc),
        });
        if (!createDocResponse.ok) {
          setShowAlert(true);
          setAlertType('ERROR');
          setDisabled(false);
          return;
        }
        const docId = await createDocResponse.json();
        const docUrl = `https://docs.google.com/document/d/${docId}/edit`;

        // Creating P2 task ticket
        const createTicketdata = {
          epic: 'CENPRO-1931',
          issueType: 'P2 Task',
          summary: title,
          description,
          labels: testTask ? 'TestData' : '',
          reporter: email,
          wuDataStructureLink: docUrl,
        };

        if (preAssignedWorker) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          createTicketdata.preAssignedWorker = preAssignedWorker;
        }

        const createTicketResponse = await fetch(`${BASE_URL}/workunit`, {
          method: 'POST',
          body: JSON.stringify(createTicketdata),
        });
        if (!createTicketResponse.ok) {
          setShowAlert(true);
          setAlertType('ERROR');
          setDisabled(false);
          return;
        }

        const ticketKey = await createTicketResponse.json();

        // Creating gDrive Folder
        const createFolderData = {
          parentId: '1ZKa92B4D0mqp9MHFEK_9Izd7L9TDAtwW',
          name: ticketKey,
        };

        const createFolderResponse = await fetch(`${BASE_URL}/folder`, {
          method: 'POST',
          body: JSON.stringify(createFolderData),
        });

        if (!createFolderResponse.ok) {
          setShowAlert(true);
          setAlertType('ERROR');
          setDisabled(false);
          return;
        }

        const ticketFolderId = await createFolderResponse.json();

        // moving p2 doc to ticket folder
        const moveFileData = {
          operation: 'move-file',
          fileUrl: docUrl,
          folderUrl: `https://drive.google.com/drive/folders/${ticketFolderId}`,
        };

        const moveFileResponse = await fetch(`${BASE_URL}/file`, {
          method: 'PUT',
          body: JSON.stringify(moveFileData),
        });

        if (!moveFileResponse.ok) {
          setShowAlert(true);
          setAlertType('ERROR');
          setDisabled(false);
          return;
        }

        // Update P2 task doc
        const updateP2DocData = {
          operation: 'update-p2-task-doc',
          fileUrl: docUrl,
          title,
          key: ticketKey,
          reporter: authContext.userEmail,
          description,
        };

        const updateFileResponse = await fetch(`${BASE_URL}/p2-task-doc`, {
          method: 'PUT',
          body: JSON.stringify(updateP2DocData),
        });

        if (!updateFileResponse.ok) {
          setShowAlert(true);
          setAlertType('ERROR');
          setDisabled(false);
          return;
        }

        setShowAlert(true);
        setAlertType('SUCCESS');
        setDisabled(false);
        setTitle('');
        setDescription('');
        setTicketKey(ticketKey);
      } catch (e) {
        console.log(e);
        setShowAlert(true);
        setAlertType('ERROR');
      }
    }
  }

  return (
    <Layout>
      <div className={'w-full mx-auto bg-white rounded-xl mt-8 p-8 shadow'}>
        <form
          className='text-left space-y-8'
          onSubmit={submitHandler}
          noValidate={true}
        >
          <div className='space-y-8  divide-gray-200 sm:space-y-5'>
            <div>
              <h3 className='text-lg leading-6 font-medium text-gray-900'>
                Create Task
              </h3>
            </div>
            <div>
              <InputText
                placeHolder={'youremail@example.com'}
                type={'email'}
                showError={emailError}
                errorMessage={'Enter valid email'}
                label={'Email'}
                name={'email'}
                value={email}
                readOnly={!authContext.isAdmin}
                setValue={(email) => {
                  setEmailError(false);
                  setEmail(email);
                }}
              />
            </div>
            <div>
              <InputText
                placeHolder={'Task title'}
                type={'text'}
                showError={titleError}
                errorMessage={'Enter task title'}
                label={'Title'}
                name={'title'}
                value={title}
                setValue={(title) => {
                  setTitleError(false);
                  setTitle(title);
                }}
              />
            </div>
            <div>
              <InputTextarea
                placeHolder={'Task description'}
                rows={4}
                showError={descriptionError}
                errorMessage={'Enter title description'}
                label={'Description'}
                name={'description'}
                value={description}
                setValue={(description) => {
                  setDescriptionError(false);
                  setDescription(description);
                }}
              />
            </div>
            {authContext.isAdmin && (
              <div>
                <InputText
                  placeHolder={'Pre Assigned Worker'}
                  type={'text'}
                  showError={false}
                  errorMessage={''}
                  label={'Pre Assigned Worker'}
                  name={'pre-assigned-worker'}
                  value={preAssignedWorker}
                  setValue={(title) => {
                    setPreAssignedWorker(title);
                  }}
                />
              </div>
            )}
            <div>
              {authContext.isAdmin && (
                <div className={'col-span-1 text-left'}>
                  <Checkbox
                    label={'Test Task'}
                    name={'test-task'}
                    checked={testTask}
                    onChange={(value) => setTestTask(value)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={'mt-2 ml-1 w-1/4'}>
            <Button
              disabled={disabled}
              type={'submit'}
              buttonText={'Create'}
              disabledText={'Creating ...'}
            />
          </div>
        </form>
        {showAlert && (
          <div className={'mt-8'}>
            <Alert
              message={
                alertType === 'SUCCESS'
                  ? `<a href='https://devgraph-alp.atlassian.net/browse/${ticketKey}' target='_blank' rel='noreferrer' class='font-bold underline'>${ticketKey}</a> task created.`
                  : 'Something went wrong. Try again later'
              }
              alertTpe={alertType}
              hideAlert={() => setShowAlert(false)}
            />
          </div>
        )}
      </div>
    </Layout>
  );
}

export default CreateTask;
