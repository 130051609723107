interface INPUT_TEXT {
  showError: boolean;
  errorMessage: string;
  label: string;
  type?: string;
  name: string;
  placeHolder?: string;
  value: string;
  setValue: (value: string) => void;
  readOnly?: boolean;
}

export default function InputText(props: INPUT_TEXT) {
  return (
    <div>
      <label
        htmlFor={props.name}
        className='block text-sm font-medium text-gray-700'
      >
        {props.label}
      </label>
      <div className='mt-1 relative rounded-md'>
        <input
          readOnly={props.readOnly}
          disabled={props.readOnly}
          type={props.type ? props.type : 'text'}
          name={props.name}
          id={props.name}
          className={
            props.showError
              ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
              : 'disabled:bg-gray-100 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md'
          }
          placeholder={props.placeHolder}
          aria-invalid={props.showError}
          aria-describedby={`${props.name}-error`}
          value={props.value}
          onChange={(event) => props.setValue(event.target.value)}
        />
      </div>
      {props.showError && (
        <p
          className='mt-2 ml-1 text-sm text-red-600'
          id={`${props.name}-error`}
        >
          {props.errorMessage}
        </p>
      )}
    </div>
  );
}
