export type TASK_ITEM = {
  key: string;
  title: string;
  status: string;
  statusGroup: string;
  issueType: string;
  reporter: string;
  preAssignedExternalReviewer: string;
  createdAt: string;
  completedAt?: string;
};

export type TABLE_HEADER = {
  columnLabel: string;
  isSortable: boolean;
  dataKey: string;
  widthPercentage?: string;
  align: 'left' | 'right' | 'center';
};

export const TABLE_HEADERS: TABLE_HEADER[] = [
  {
    columnLabel: 'Title',
    isSortable: true,
    dataKey: 'summary',
    widthPercentage: '45%',
    align: 'left',
  },
  {
    columnLabel: 'Status',
    isSortable: true,
    dataKey: 'statusGroup',
    widthPercentage: '15%',
    align: 'right',
  },
  {
    columnLabel: 'Ordered',
    isSortable: true,
    dataKey: 'createdAt',
    widthPercentage: '15%',
    align: 'right',
  },
  {
    columnLabel: 'Completed',
    isSortable: true,
    dataKey: 'completedAt',
    widthPercentage: '15%',
    align: 'right',
  },
  {
    columnLabel: 'Spec Link',
    isSortable: false,
    dataKey: 'id',
    widthPercentage: '10%',
    align: 'right',
  },
];
