import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from './store/AuthContext';
import { Hub, Auth } from 'aws-amplify';
import { HubCallback } from '@aws-amplify/core/lib/Hub';
import './App.css';
import CreateTask from './pages/CreateTask';
import MyTasks from './pages/MyTasks';

function App() {
  const authContext = useContext(AuthContext);
  const authListener: HubCallback = async (data) => {
    switch (data.payload.event) {
      case 'signIn': {
        authContext.setAuthMessage('Signing in...');
        const user = await Auth.currentAuthenticatedUser();
        authContext.setAuthMessage('Sign in successful');
        authContext.setUserEmail(user.signInUserSession.idToken.payload.email);
        localStorage.setItem('isSigningIn', 'false');
        break;
      }
      case 'signOut':
        localStorage.setItem('isSigningIn', 'false');
        authContext.setAuthMessage('Sign in to continue');
        authContext.setUserEmail('');
        break;
      case 'signIn_failure':
        localStorage.setItem('isSigningIn', 'false');
        authContext.setAuthMessage(
          'Please use any devfactory.com or trilogy.com accounts. <br /> If you think your domain should be in allow list, please reach out to <a class="text-blue-500" href="mailto:central-product-orders@devfactory.com"> central-product-tpm-group@devfactory.com </a>',
        );
        break;
    }
  };
  Hub.listen('auth', authListener);

  useEffect(() => {
    const isSigningIn = localStorage.getItem('isSigningIn');
    if (isSigningIn && isSigningIn === 'true')
      authContext.setAuthMessage('Signing in...');
  }, []);

  useEffect(() => {
    authContext.setIsLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups =
          user.signInUserSession.accessToken.payload['cognito:groups'];
        if (groups.includes('Admin')) authContext.setIsAdmin(true);
        authContext.setUserEmail(user.signInUserSession.idToken.payload.email);
        authContext.setRequesterEmail(
          user.signInUserSession.idToken.payload.email,
        );
      })
      .finally(() => {
        authContext.setIsLoading(false);
      });
  }, []);

  return (
    <div className='App'>
      <Routes>
        <Route path={'/'} element={<Navigate to={'create-task'} />} />
        <Route path={'create-task'} element={<CreateTask />} />
        <Route path={'my-task-requests'} element={<MyTasks />} />
      </Routes>
    </div>
  );
}

export default App;
