import React, { useContext } from 'react';
import { Auth } from 'aws-amplify';
import { AuthContext } from '../../store/AuthContext';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';

import GoogleIcon from '../../GoogleIcon.svg';
import ButtonOutlined from './ButtonOutlined';

function AuthButton() {
  const authContext = useContext(AuthContext);

  async function handleSignIn() {
    try {
      localStorage.setItem('isSigningIn', 'true');
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function handleSignOut() {
    try {
      await Auth.signOut();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <ButtonOutlined
      buttonText={authContext.userEmail ? 'Sign out' : 'Sign in'}
      onClick={authContext.userEmail ? handleSignOut : handleSignIn}
      Icon={
        <img
          alt={'Google Icon'}
          src={GoogleIcon}
          className='-ml-1 mr-2 h-5 w-5'
        />
      }
    />
  );
}

export default AuthButton;
