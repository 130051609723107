import React, { useContext } from 'react';
import { AuthContext } from '../../store/AuthContext';
import AppNavbar from './AppNavbar';

interface LAYOUT {
  children: React.ReactNode;
}

function Layout(props: LAYOUT) {
  const authContext = useContext(AuthContext);
  return (
    <React.Fragment>
      <AppNavbar />
      <main className={'max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mb-32'}>
        {authContext.isLoading && <div className={'mt-8'}>Loading...</div>}
        {!authContext.isLoading && authContext.userEmail && props.children}
        {!authContext.isLoading && !authContext.userEmail && (
          <div
            className={'mt-8'}
            dangerouslySetInnerHTML={{ __html: authContext.authMessage }}
          />
        )}
      </main>
    </React.Fragment>
  );
}

export default Layout;
