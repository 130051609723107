import { createContext, ReactNode, useState } from 'react';

const initialValue = {
  isLoading: false,
  setIsLoading: (isLoading: boolean) => {},
  isAdmin: false,
  setIsAdmin: (isAdmin: boolean) => {},
  userEmail: '',
  setUserEmail: (userEmail: string) => {},
  authMessage: 'Sign in to continue',
  setAuthMessage: (message: string) => {},
  requesterEmail: '',
  setRequesterEmail: (email: string) => {},
};

export const AuthContext = createContext(initialValue);

export default function AuthContextProvider(props: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [requesterEmail, setRequesterEmail] = useState('');
  const [authMessage, setAuthMessage] = useState('Sign in to continue');

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        setIsLoading: (isLoading) => setIsLoading(isLoading),
        isAdmin,
        setIsAdmin: (isAdmin) => setIsAdmin(isAdmin),
        userEmail,
        setUserEmail: (userEmail) => setUserEmail(userEmail),
        authMessage,
        setAuthMessage: (authMessage) => setAuthMessage(authMessage),
        requesterEmail,
        setRequesterEmail: (email) => setRequesterEmail(email),
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
