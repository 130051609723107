import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../store/AuthContext';
import Table from '../ui/Table';
import { BASE_URL, statusMap } from '../../constants/constants';
import { TABLE_HEADERS, TASK_ITEM } from '../../constants/model';
import { compareObjects } from '../../utils';

function TaskTable() {
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [tasks, setTasks] = useState<TASK_ITEM[]>([]);
  const [totalTasks, setTotalTasks] = useState(0);
  const [pageSize, setPageSize] = useState('20');
  const [pageNumber, setPageNumber] = useState(1);

  function sortTableRows(order: boolean, orderBy: string) {
    const tableData = JSON.parse(JSON.stringify(tasks));
    tableData.sort((a: any, b: any) =>
      compareObjects(a, b, order ? 'asc' : 'dsc', orderBy),
    );
    setTasks(tableData);
  }

  async function fetchTasks(pageSize?: string, pageNumber?: number) {
    setIsLoading(true);
    const getUrl = `${BASE_URL}/workunit?issueType=${encodeURIComponent(
      'P2 Task',
    )}&reporter=${encodeURIComponent(
      authContext.requesterEmail,
    )}&pageSize=${pageSize}&page=${pageNumber}`;

    console.log(getUrl);

    const res = await fetch(getUrl);
    if (!res.ok) console.log(res);
    else {
      const data = await res.json();
      console.log(data);
      const items = data.items;
      const updatedItems = items.map((item: any) => {
        return {
          ...item,
          statusGroup: statusMap.get(item.status),
        };
      });
      setTasks(updatedItems);
      setTotalTasks(data.total);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setPageNumber(1);
    fetchTasks(pageSize, 1);
  }, [authContext.requesterEmail]);

  return (
    <>
      {isLoading && <div>Loading...</div>}
      {!isLoading && tasks.length === 0 && <div>No tasks found.</div>}
      {!isLoading && tasks.length > 0 && (
        <Table
          tableHeaders={TABLE_HEADERS}
          orders={tasks}
          total={totalTasks}
          pageSize={pageSize}
          pageNumber={pageNumber}
          pageSizeList={['10', '20', '50', '100']}
          pageSizeChange={(value) => {
            setPageSize(value);
            setPageNumber(1);
            fetchTasks(value, 1);
          }}
          currentPageChange={(value) => {
            setPageNumber(value);
            fetchTasks(pageSize, value);
          }}
          sortTableRows={(order, orderBy) => sortTableRows(order, orderBy)}
        />
      )}
    </>
  );
}

export default TaskTable;
