import React from 'react';

interface BUTTON {
  type?: 'button' | 'submit' | 'reset' | undefined;
  buttonText: string;
  disabled: boolean;
  disabledText?: string;
}

function Button(props: BUTTON) {
  return (
    <button
      disabled={props.disabled}
      type={props.type ? props.type : 'button'}
      className='disabled:opacity-60 disabled:cursor-progress w-full flex justify-center px-4 py-2 border
      border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600
      hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2
      focus:ring-blue-500'
    >
      {props.disabled ? props.disabledText : props.buttonText}
    </button>
  );
}

export default Button;
